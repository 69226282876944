<template>
  <div class="supply_content_sty">
    <div class="stem">
      <span class="index">{{ idx }}.</span>
      <p class="question_title" v-html="question.question"></p>
      <b class="question_score">{{ question.score }}分</b>
    </div>
    <div class="answer_content_sty">
        <div>
            我的答案:<span>{{question.myAnswer}}</span>
        </div>
        <div v-if="isCanSeeAnswer">
            正确答案:<span v-html="question.answer" class="rich_text_sty"></span>
        </div>
        <div v-if="isCanSeeAnswer">
            解析:<span v-html="question.answerDesc" class="rich_text_sty"></span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: {
    // 索引
    idx: {
      type: Number,
      default: null,
    },
    // 题目对象
    question: {
      type: Object,
      default: function () {
        return {};
      },
    },
    // 是否可查看答案
    isCanSeeAnswer: {
        type:Boolean,
        default:true
    }
  },
  watch: {
    
  },
  computed: {},
  created() {

  },
};
</script>


<style lang="less" scoped>
.stem {
  margin-bottom: 20px;
  .index {
    display: inline-block;
    vertical-align: middle;
  }
  .question_title {
    display: inline-block;
    max-width: 90%;
    font-size: 14px;
    color: #333;
    vertical-align: middle;
    /deep/ img {
      margin: 0 5px !important;
      //   width: 100%;
      //   max-height: 60px !important;
    }
  }
  .question_score {
    display: inline-block;
    margin-left: 20px;
    color: #999;
    font-size: 14px;
    vertical-align: middle;
  }
}
.question_answer_content_sty {
  width: 100%;
  /deep/ .el-textarea.is-disabled .el-textarea__inner {
      background: #F8F8F8  !important;
  }
}
.answer_content_sty {
    background: #F8F8F8;
    padding: 16px 13px;
    box-sizing: border-box;
    margin-top: 24px;
    & > div {
        margin-top: 8px;
        &:nth-child(2) {
            color: #0D79FF;
        }
    }
}
.rich_text_sty {
    /deep/ p {
        display: inline-block;
    }
}
</style>
<template>
  <div class="supply_content_sty">
    <div class="stem">
      <span class="index">{{ idx }}.</span>
      <p class="question_title" v-html="question.question"></p>
      <b class="question_score">{{ question.score }}分</b>
    </div>
    <div class="question_answer_content_sty">
      <div
        class="question_answer_content_item_sty"
        v-for="(i, index) in myKey"
        :key="index"
      >
        <div class="question_answer_content_item_index_sty">
          {{ index + 1 }}
        </div>
        <div class="question_answer_content_item_input_sty">
            {{i}}
          <!-- <el-input placeholder="请输入答案"  v-model="i" disabled>
          </el-input> -->
        </div>
      </div>
    </div>
    <div class="answer_content_sty" v-if="isCanSeeAnswer">
        <div>
            正确答案:<span v-for="(r,index) in rightKey" :key="index">{{index+1}}、<span v-html="r" class="rich_text_sty blanks_sty"></span></span>
        </div>
        <div>
            解析:<span v-html="question.answerDesc" class="rich_text_sty"></span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    //   answerList: [],
    };
  },
  props: {
    // 索引
    idx: {
      type: Number,
      default: null,
    },
    // 题目对象
    question: {
      type: Object,
      default: function () {
        return {};
      },
    },
    // 是否可查看答案
    isCanSeeAnswer: {
        type:Boolean,
        default:true
    }
  },
  watch: {
    
  },
  computed: {
      rightKey:function() {
         return this.question.answer.split('@;@')
      },
      myKey: function() {
          return this.question.myAnswer.split('@;@')
      }
  },
  created() {
    // let num = this.question.emptyCount ? this.question.emptyCount : 0;
    // let list = [];
    // if (num !== 0) {
    //   for (let i = 0; i <= num; i++) {
    //     let obj = {};
    //     obj[`answer${i + 1}`] = "";
    //     list.push(obj);
    //   }
    // }
    // this.answerList = list;
  },
};
</script>


<style lang="less" scoped>
.stem {
  margin-bottom: 20px;
  .index {
    display: inline-block;
    vertical-align: middle;
  }
  .question_title {
    display: inline-block;
    max-width: 90%;
    font-size: 14px;
    color: #333;
    vertical-align: middle;
    /deep/ img {
      margin: 0 5px !important;
      //   width: 100%;
      //   max-height: 60px !important;
    }
  }
  .question_score {
    display: inline-block;
    margin-left: 20px;
    color: #999;
    font-size: 14px;
    vertical-align: middle;
  }
}
.question_answer_content_sty {
  width: 100%;
  .question_answer_content_item_sty {
    width: 100%;
    height: 38px;
    &:not(:first-child) {
      margin-top: 20px;
    }
    & > div {
      display: inline-block;
      vertical-align: top;
    }
    .question_answer_content_item_index_sty {
      width: 41px;
      height: 100%;
      background: #e6e6e6;
      text-align: center;
      line-height: 38px;
    }
    .question_answer_content_item_input_sty {
      width: 863px;
      height: 38px;
      line-height: 38px;
      position: relative;
      border: 1px solid #e6e6e6;
      background: #F8F8F8;
      padding-left: 11px;
      & > input {
        width: 100%;
        padding-left: 10px;
        box-sizing: border-box;
      }
      /deep/ .el-input__inner {
          border: none !important;
          height: 36px;
          display: inline-block;
          vertical-align: top;
          line-height: 36px;
      }
      & > i {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #bdbdbd;
      }
    }
  }
}
.answer_content_sty {
    background: #F8F8F8;
    padding: 16px 13px;
    box-sizing: border-box;
    margin-top: 24px;
    & > div {
        margin-top: 8px;
        &:nth-child(2) {
            color: #0D79FF;
        }
    }
}
.blanks_sty {
    padding-right: 12px;
}
.rich_text_sty {
    /deep/ p {
        display: inline-block;
    }
}
</style>
<template>
  <div>
    <div class="stem">
      <span class="index">{{ idx }}.</span>
      <p class="question_title" v-html="question.question"></p>
      <b class="question_score">{{ question.score }}分</b>
    </div>
    <div
      class="one_option"
      v-for="i in question.optionList"
      :key="i.sort"
    >
      <div class="option">
        {{ i.sort }}
      </div>
      <div class="option_text" v-html="i.value"></div>
    </div>
    <div class="answer_content_sty">
        <div>
            我的答案:{{question.myAnswer}}
        </div>
        <div v-if="isCanSeeAnswer">
            正确答案:{{question.answer}}
        </div>
        <div v-if="isCanSeeAnswer">
            解析:<span v-html="question.answerDesc" class="rich_text_sty"></span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "single",
  props: {
    // 索引
    idx: {
      type: Number,
      default: null,
    },
    // 题目对象
    question: {
      type: Object,
      default: function () {
        return {};
      },
    },
    // 是否可查看答案
    isCanSeeAnswer: {
        type:Boolean,
        default:true
    }
  },
  created() {
     
  },
  methods: {
    // 判断是否已存在在数组中
    isInArray(arr, item) {
      return arr.some((e) => e.sort === item.sort);
    }
  },
  data() {
    return {
      answerList: [],
    };
  },
};
</script>

<style lang="less" scoped>
.stem {
  margin-bottom: 20px;
  .index {
    display: inline-block;
    vertical-align: middle;
  }
  .question_title {
    display: inline-block;
    max-width: 90%;
    font-size: 14px;
    color: #333;
    vertical-align: middle;
    // img {
    //   margin: 0 5px !important;
    //   max-height: 30px !important;
    // }
  }
  .question_score {
    display: inline-block;
    margin-left: 20px;
    color: #999;
    font-size: 14px;
    vertical-align: middle;
  }
}
.one_option {
  margin-bottom: 15px;
  &.checked {
    .option {
      background: #e5f1ff;
      color: #0d79ff;
      border-color: #e5f1ff !important;
    }
    .option_text {
      color: #0d79ff;
    }
  }
  .option {
    display: inline-block;
    width: 38px;
    text-align: center;
    line-height: 38px;
    height: 38px;
    border-radius: 50%;
    border: 1px solid #e6e6e6;
    cursor: pointer;
  }
  .option_text {
    display: inline-block;
    margin-left: 16px;
    img {
      margin: 0 5px !important;
      max-height: 30px !important;
    }
  }
}
.answer_content_sty {
    background: #F8F8F8;
    padding: 16px 13px;
    box-sizing: border-box;
    & > div {
        margin-top: 8px;
        &:nth-child(2) {
            color: #0D79FF;
        }
    }
}
.rich_text_sty {
    /deep/ p {
        display: inline-block;
    }
}
</style>
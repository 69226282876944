<template>
  <div class="test_paper_content_sty">
    <div class="test_paper_content_title_sty">
      <div class="test_paper_info_sty">
        <div class="back_icon_sty">
          <i class="iconfont" @click="goBack">&#xe625;</i
          ><span>{{ testInfo.title }}</span>
        </div>
        <div>
          <i class="iconfont icon_col_sty">&#xe633;</i
          ><span>数量:{{ totalQuestionNum }}</span
          ><i class="iconfont icon_col_sty">&#xe634;</i
          ><span>满分:{{ testInfo.totalScore }}</span>
          <i class="iconfont icon_col_sty">&#xe635;</i
          ><span
            >考试时间:{{ formatDate(testInfo.startTime, "MM-DD HH:mm") }} 至
            {{ formatDate(testInfo.endTime, "MM-DD HH:mm") }}</span
          >
        </div>
      </div>
      <div class="count_down_content_sty">
        <div v-if="reviewPaperContent.score">
          <img src="@/assets/img/fenshu.png" />
          <span>{{ reviewPaperContent.score }}</span> 分
        </div>
      </div>
    </div>
    <div class="review_question_content_sty">
      <div
        class="review_question_item_sty"
        v-for="(i, index) in radioQuestions"
        :key="index"
      >
        <div class="review_question_title_sty">
          {{ questionIndex(index + 1) }}、{{ questionTitle(Object.keys(i)[0])
          }}<span
            >（共{{ i[Object.keys(i)[0]] ? i[Object.keys(i)].length : 0 }}题
            {{ totleScore(i[Object.keys(i)]) }}分）</span
          >
        </div>
        <div class="review_question_main_sty">
          <!-- 单选题 -->
          <single
            v-for="(i, index) in i.radio"
            :key="i.questionId"
            :question="i"
            :idx="index + 1"
            class="question_sty"
            :isCanSeeAnswer="isCanSeeAnswer"
          />
          <!-- 多选题 -->
          <single
            v-for="(i, index) in i.checkbox"
            :key="i.questionId"
            :question="i"
            :idx="index + 1"
            class="question_sty"
            :isCanSeeAnswer="isCanSeeAnswer"
          />
          <!-- 判断题 -->
          <judge
            v-for="(i, index) in i.boolean"
            :key="i.questionId"
            :question="i"
            :idx="index + 1"
            class="question_sty"
            :isCanSeeAnswer="isCanSeeAnswer"
          />
          <!-- 填空题 -->
          <blanks
            v-for="(i, index) in i.supply"
            :key="i.questionId"
            :question="i"
            :idx="index + 1"
            class="question_sty"
            :isCanSeeAnswer="isCanSeeAnswer"
          />
          <!-- 简答题 -->
          <answer
            v-for="(i, index) in i.answer"
            :key="i.questionId"
            :question="i"
            :idx="index + 1"
            class="question_sty"
            :isCanSeeAnswer="isCanSeeAnswer"
          />
        </div>
      </div>
      <div class="restart_btn_content_sty">
        <div
          class="restart_btn_sty"
          v-if="
            testInfo.redo && (testInfo.redoMaxCount - testInfo.testCount) >= 0 && testInfo.endTime > now
          "
          @click="reDialogVisible = true"
        >
          重考
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="reDialogVisible" width="500px">
      <reexamination-tip @close="close" :testPaperContent="testInfo" />
    </el-dialog>
  </div>
</template>


<script>
import { reviewPaper } from "@/api/examination";
import { getTestInfo } from "@/api/test";
import { formatDate } from "@/utils/index";
import single from "../reviewQuestion/single/index";
import mixinsData from "@/mixins/data";
import judge from "../reviewQuestion/judge/index";
import blanks from "../reviewQuestion/blanks/index";
import answer from "../reviewQuestion/answer/index";
import reexaminationTip from "../testTip/reexaminationTip";
import getCurrentTime from "@/mixins/getCurrentTime"
export default {
  components: {
    single,
    judge,
    blanks,
    answer,
    reexaminationTip
  },
  mixins: [mixinsData,getCurrentTime],
  data() {
    return {
      reviewPaperContent: {},
      testInfo: {},
      formatDate,
      reDialogVisible:false
    };
  },
  created() {
    this.reviewPaper();
    this.getTestInfo();
    this.getCurrentTime();
  },
  computed: {
    totalQuestionNum: function () {
      return this.reviewPaperContent.questionList
        ? this.reviewPaperContent.questionList.length
        : 0;
    },
    // 格式化题目
    radioQuestions: function () {
      let list = [];
      if (this.reviewPaperContent.questionList) {
        this.reviewPaperContent.questionList.forEach((e) => {
          let type = e.type;
          let obj = {};
          if (list.length == 0) {
            obj[type] = [e];
            list.push(obj);
          } else {
            let isHas = false;
            list.forEach((item) => {
              let currenType = Object.keys(item)[0];
              if (currenType == type) {
                item[currenType].push(e);
                isHas = true;
              }
            });
            if (!isHas) {
              let newObj = {};
              newObj[type] = [e];
              list.push(newObj);
            }
          }
        });
      }
      return list;
    },
    // 获取当前时间
    now: function () {
      return this.$store.state.currentTime;
    },
    // 是否允许查看答案
    isCanSeeAnswer: function () {
      if (this.testInfo.viewTestPaperAnswer == -1) {
        return false;
      } else if (this.testInfo.viewTestPaperAnswer == 0) {
        return true;
      } else if (
        this.testInfo.viewTestPaperAnswer == 1 &&
        this.testInfo.endTime <= this.now
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    goBack() {
        this.$router.go(-1)
    },
    // 重看试卷的信息
    reviewPaper() {
      let id = this.$route.query.id || null;
      let that = this;
      this.$loading.show();
      reviewPaper(id).then((json) => {
        that.$loading.hide()
        const { code, data, msg } = json;
        if (code === 200) {
          this.reviewPaperContent = data;
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 试卷的信息
    getTestInfo() {
      let id = this.$route.query.id || null;
      getTestInfo(id).then((json) => {
        const { code, data, msg } = json;
        if (code === 200) {
          this.testInfo = data;
        } else {
          this.$message.error(msg);
        }
      });
    },
    close() {
        this.reDialogVisible = false;
    }
  },
};
</script>

<style lang="less" scoped>
.test_paper_content_sty {
  width: 100%;
  text-align: left;
}
.test_paper_content_title_sty {
  height: 86px;
  padding-left: 33px;
  box-sizing: border-box;
  position: relative;
  .test_paper_info_sty {
    padding-top: 21px;
    .back_icon_sty {
      i {
        cursor: pointer;
        display: inline-block;
        // vertical-align: middle;
      }
    }
    & > div {
      &:first-child {
        margin-bottom: 10px;
      }
      &:nth-child(2) {
        color: #666;
        i {
          margin-right: 4px;
          &:not(:first-child) {
            margin-left: 8px;
          }
        }
      }
    }
  }
  .count_down_content_sty {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 33px;
    font-size: 20px;
    color: #999;
    img {
      width: 29px;
      display: inline-block;
      vertical-align: top;
      margin-right: 4px;
    }
    span {
      color: #333;
      font-weight: bold;
      font-size: 20px;
    }
    // & > div {
    //   width: 124px;
    //   height: 38px;
    //   background: #0d79ff;
    //   border-radius: 19px;
    //   text-align: center;
    //   line-height: 38px;
    //   color: #fff;
    // }
    span {
      width: 20px;
      text-align: center;
      display: inline-block;
      font-size: 20px;
    }
  }
  .icon_col_sty {
    color: #0d79ff;
  }
}
.review_question_content_sty {
  width: 100%;
  & > .review_question_item_sty {
    width: 100%;
    & > .review_question_title_sty {
      height: 48px;
      line-height: 48px;
      padding-left: 32px;
      box-sizing: border-box;
      background: #f6f6f6;
      font-size: 18px;
      color: #333;
      & > span {
        font-size: 14px;
        color: #999;
      }
    }
    & > .review_question_main_sty {
      background: #fff;
      padding: 30px;
      padding-top: 10px;
      box-sizing: border-box;
    }
  }
}
.question_sty {
  margin-top: 20px;
}
.restart_btn_content_sty {
    width: 100%;
    text-align: right;
    margin-bottom: 20px;
    padding-right: 30px;
    box-sizing: border-box;
    cursor: pointer;
}
.restart_btn_sty {
    display: inline-block;
  width: 124px;
  height: 38px;
  background: #0d79ff;
  border-radius: 19px;
  color: 14px;
  color: #fff;
  text-align: center;
  line-height: 38px;
}
</style>